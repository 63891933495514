import type { Breakpt } from '../utils/breakptTypes'

const breakpts: Record<Breakpt, number> = {
  s: 414,
  m: 768,
  l: 1024,
  xl: 1280,
  xxl: 2560
}

export default breakpts
