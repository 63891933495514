import WorkImg from '../../common/media/workImg'
import RowContainer from '../../common/rowContainer'


const PageMemoryOfMemories = () => {
  return (
    <>
      <RowContainer>
        <WorkImg src='1.webp' />
      </RowContainer>
      <RowContainer toolTip='The book is hand-bound via kettle-stitching. The paper is Legion Revere Book: the print on which rubs off over time—making it an unsuitable for regular printing but an appropriate one for the project.'>
        <WorkImg src='2.webp' />
        <WorkImg src='3.webp' />
      </RowContainer>
      <RowContainer>
        <WorkImg src='4.webp' />
        <WorkImg src='5.webp' />
        <WorkImg src='6.webp' />
      </RowContainer>
      <RowContainer>
        <WorkImg src='7.webp' />
      </RowContainer>
      <RowContainer>
        <WorkImg src='8.webp' />
        <WorkImg src='9.webp' />
      </RowContainer>
      <RowContainer>
        <WorkImg src='10.webp' />
        <WorkImg src='11.webp' />
      </RowContainer>
      <RowContainer>
        <WorkImg src='12.webp' />
      </RowContainer>
      <RowContainer toolTip='Quote paraphrased from Experimental Serial Lain (1998).'>
        <WorkImg src='13.webp' />
        <WorkImg src='14.webp' />
      </RowContainer>
      <RowContainer>
        <WorkImg src='15.webp' />
      </RowContainer>
      <RowContainer>
        <WorkImg src='16.webp' />
      </RowContainer>
    </>
  )
}


export default PageMemoryOfMemories