export enum Breakpt {
  S = 's',
  M = 'm',
  L = 'l',
  Xl = 'xl',
  Xxl = 'xxl'
}

export enum Device {
  Mobile = 'mobile',
  Desktop = 'desktop'
}