export enum LinkPath {
  Work = 'work',
  Process = 'process',
  Contact = 'contact'
}

export enum LinkText {
  Work = 'WORK',
  Process = 'HOW I WORK',
  Contact = 'CONTACT'
}